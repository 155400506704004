import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingCard } from '../../components';
import css from './SimilarListingsCarousel.module.css';

const SimilarListingsCarousel = ({ similarListings, config, title, fromEvent, keyword }) => {
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [isMobile, setIsMobile] = useState(false);
  const carouselRef = useRef(null); // Always use refs at the top level
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const startY = useRef(0); // Declare ref for Y-axis movement to track vertical scroll

  // This effect manages the resize event listener to adjust items per page
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setItemsPerPage(mobile ? 2.5 : 6);
    };

    handleResize(); // Call it immediately to set initial state
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Clean up
  }, []); // This effect only runs on mount/unmount

  if (!similarListings || similarListings.length === 0) {
    return null;
  }

  // Event handler for previous button click
  const handlePrevClick = () => {
    const slideWidth = carouselRef.current.offsetWidth;
    carouselRef.current.scrollBy({ left: -slideWidth, behavior: 'smooth' });
  };

  // Event handler for next button click
  const handleNextClick = () => {
    const slideWidth = carouselRef.current.offsetWidth;
    carouselRef.current.scrollBy({ left: slideWidth, behavior: 'smooth' });
  };

  // Start drag (on mousedown or touchstart)
  const handleDragStart = e => {
    if (isMobile) return;
    isDragging.current = true;
    startX.current = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
    startY.current = e.type.includes('mouse') ? e.pageY : e.touches[0].clientY; // Track Y-axis start point
    scrollLeft.current = carouselRef.current.scrollLeft;
  };

  // End drag (on mouseup or touchend)
  const handleDragEnd = () => {
    isDragging.current = false;
  };

  // Handle drag movement (on mousemove or touchmove)
  const handleDragMove = e => {
    if (!isDragging.current || isMobile) return;

    const x = e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
    const y = e.type.includes('mouse') ? e.pageY : e.touches[0].clientY;

    // Calculate horizontal and vertical distances moved
    const walkX = (x - startX.current) * 2;
    const walkY = y - startY.current;

    // If vertical movement is more than horizontal, stop dragging
    if (Math.abs(walkY) > Math.abs(walkX)) {
      isDragging.current = false;
      return;
    }

    // Prevent default if horizontal movement exceeds vertical movement
    e.preventDefault();
    carouselRef.current.scrollLeft = scrollLeft.current - walkX;
  };

  return (
    <div className={css.similarListingsSection}>
      <div className={css.titleContainer}>
        <h2 className={fromEvent ? css.similarListingsTitleEvent : css.similarListingsTitle}>
          {fromEvent ? title : <FormattedMessage id={title} />}
        </h2>
        {fromEvent && (
          <a
            href={keyword ? `/s?keywords=${encodeURIComponent(keyword.toLowerCase())}` : '/s'}
            className={css.seeMoreLink}
          >
            See More
          </a>
        )}
      </div>
      <div className={css.carouselContainer}>
        {!isMobile && (
          <button className={css.carouselButton} onClick={handlePrevClick}>
            &lt;
          </button>
        )}
        <div
          className={css.carouselWrapper}
          ref={carouselRef}
          onMouseDown={handleDragStart}
          onMouseLeave={handleDragEnd}
          onMouseUp={handleDragEnd}
          onMouseMove={handleDragMove}
          onTouchStart={handleDragStart}
          onTouchEnd={handleDragEnd}
          onTouchMove={handleDragMove}
        >
          <div className={css.carouselTrack}>
            {similarListings.map(listing => (
              <div
                key={listing.id.uuid}
                className={css.carouselItem}
                style={{
                  flex: `0 0 ${100 / itemsPerPage}%`,
                  maxWidth: `${100 / itemsPerPage}%`,
                  width: `${100 / itemsPerPage}%`,
                }}
              >
                <ListingCard
                  listing={listing}
                  showAuthorInfo={true}
                  config={config}
                  isShort={true}
                />
              </div>
            ))}
          </div>
        </div>
        {!isMobile && (
          <button className={css.carouselButton} onClick={handleNextClick}>
            &gt;
          </button>
        )}
      </div>
    </div>
  );
};

export default SimilarListingsCarousel;
